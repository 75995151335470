<template>
  <section class="app-ecommerce-details margin-bt">
    <!-- Content -->
    <b-card
      v-if="product"
      no-body
    >
      <b-card-body>
        <b-row class="my-2">

          <!-- Left: Product Image Container -->
          <b-col
            cols="12"
            md="5"
            class="mb-2"
          >
            <div class="d-flex align-items-center justify-content-center">
              <template v-if="product.selectedPicture === 'phone'">
                <b-img
                  :src="require(`@/assets/images/cards/${selectedColor}.png`)"
                  :alt="`Image of ${product.name}`"
                  class="product-img"
                  fluid
                />
              </template>
              <template v-else>
                <b-img
                  :src="require(`@/assets/images/cards/${selectedColor}.png`)"
                  :alt="`Image of ${product.name}`"
                  class="product-img"
                  fluid
                />
              </template>
            </div>
            <e-commerce-product-card-details-image-slider />
          </b-col>

          <!-- Right: Product Details -->
          <b-col
            cols="12"
            md="6"
            offset-md="1"
            align-self="center"
          >

            <!-- Product Name -->
            <!-- <h2>{{ product.name }} - {{ selectedColor }}</h2> -->
            <h2>{{ product.name }}</h2>

            <!-- Avability -->
            <b-card-text class="mt-2">
              {{ $t('idy.cardDetailPage.cardDetails.stockInfo') }} <span class="text-success">{{ $t('idy.cardDetailPage.cardDetails.inStock') }}</span>
            </b-card-text>

            <!-- Product Description -->
            <b-card-text>
              <b-row>
                <b-col cols="12">
                  <span>
                    {{ $t('idy.cardDetailPage.cardDetails.description') }}
                  </span>
                </b-col>
              </b-row>
              <hr/>
              <b-row>
                <b-col cols="12">
                  <table>
                    <tr class="pb-2">
                      <th width="35%">
                        <b>{{ $t('idy.cardDetailPage.cardDetails.technicalInfo') }}</b>
                      </th>
                    </tr>
                    <tr>
                      <td><b>{{ $t('idy.cardDetailPage.cardDetails.weightTitle') }}</b></td>
                      <td>{{ $t('idy.cardDetailPage.cardDetails.weightText') }}</td>
                    </tr>
                    <tr>
                      <td><b>{{ $t('idy.cardDetailPage.cardDetails.dimensionsTitle') }}</b></td>
                      <td>{{ $t('idy.cardDetailPage.cardDetails.dimensionsText') }}</td>
                    </tr>
                    <tr>
                      <td><b>{{ $t('idy.cardDetailPage.cardDetails.colorsTitle') }}</b></td>
                      <td>{{ $t('idy.cardDetailPage.cardDetails.colorsText') }}</td>
                    </tr>
                    <tr>
                      <td><b>{{ $t('idy.cardDetailPage.cardDetails.materialsTitle') }}</b></td>
                      <td>{{ $t('idy.cardDetailPage.cardDetails.materialsText') }}</td>
                    </tr>
                    <tr>
                      <td><b>{{ $t('idy.cardDetailPage.cardDetails.chipTitle') }}</b></td>
                      <td>{{ $t('idy.cardDetailPage.cardDetails.chipText') }}</td>
                    </tr>
                  </table>
                </b-col>
              </b-row>
              <hr/>
            </b-card-text>

            <!-- Product Meta [Free shpping, EMI, etc.] -->
            <ul class="product-features list-unstyled">
              <li v-if="product.hasFreeShipping">
                <feather-icon icon="TruckIcon" />
                <span>{{ $t('idy.cardDetailPage.cardDetails.freeShipping') }}</span>
              </li>
              <li>
                <feather-icon icon="PackageIcon" />
                <span>{{ $t('idy.cardDetailPage.cardDetails.deliveryTime') }}</span>
              </li>
              <li>
                <feather-icon icon="CheckCircleIcon" />
                <span>{{ $t('idy.cardDetailPage.cardDetails.personalization') }}</span>
              </li>
              <li>
                <Plant />
                <span>{{ $t('idy.cardDetailPage.cardDetails.protectNature') }}</span>
              </li>
            </ul>

            <hr>

            <!-- Colors -->
            <div class="product-color-options">
              <h6>{{ $t('idy.cardDetailPage.cardDetails.colorOptions') }}</h6>
              <ul class="list-unstyled mb-0">
                <li
                  v-for="color in product.colorOptions"
                  :key="color"
                  class="d-inline-block"
                  :class="{'selected': selectedColor === color}"
                  @click="changeColor(color)"
                >
                  <div
                    class="color-option"
                    :class="`b-${color}`"
                  >
                    <div
                      class="filloption"
                      :class="`bg-${color}`"
                    />
                  </div>
                </li>
              </ul>
            </div>

            <hr>

            <!-- Whatsapp -->
            <div class="d-flex flex-column flex-sm-row pt-1">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="success"
                class="btn-cart mr-0 mr-sm-1 mb-1 mb-sm-0"
                @click="contactUs"
              >
                <h1 style="font-size:1.7rem; color:white">
                  <BrandWhatsapp class="mr-50" />
                  <span style="font-size:1.0rem;">{{ $t('idy.cardDetailPage.cardDetails.contactUs') }}</span>
                </h1>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-card-body>

      <!-- Static Content -->
      <!-- <e-commerce-product-details-item-features /> -->

      <!-- Static Content -->
      <!-- Slider: Related Products -->
      <!-- <e-commerce-product-details-related-products /> -->
    </b-card>
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
// import { useRouter } from '@core/utils/utils'
// import store from '@/store'
// import { ref } from '@vue/composition-api'
import {
  BImg, BCard, BCardBody, BRow, BCol, BCardText, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { v4 as uuidv4 } from 'uuid'
import { Plant, BrandWhatsapp } from 'vue-icon-packs/ti'
// import ECommerceProductDetailsItemFeatures from './ECommerceProductDetailsItemFeatures.vue'
import ECommerceProductCardDetailsImageSlider from './ECommerceProductCardDetailsImageSlider.vue'
// import { useEcommerceUi } from '../useEcommerce'

export default {
  directives: {
    Ripple,
  },
  components: {
    BImg,
    BCard,
    BCardBody,
    BRow,
    BCol,
    BCardText,
    BButton,
    ECommerceProductCardDetailsImageSlider,
    // ECommerceProductDetailsItemFeatures,
    Plant,
    BrandWhatsapp,
  },
  data() {
    return {
      backgroundImage: '',
    }
  },
  computed: {
    ...mapGetters({
      product: 'eCommerce/getCardProductDetail',
      selectedColor: 'eCommerce/getCardColorType',
    }),
  },
  mounted() {
  },
  methods: {
    ...mapActions({
      changeTagColors: 'eCommerce/changeCardColors',
      addBasketItem: 'eCommerce/addBasketItem',
    }),
    changeColor(color) {
      this.changeTagColors(color)
    },
    addCart() {
      let colorId = 0
      switch (this.selectedColor) {
        case 'tagBlack':
          colorId = 1
          break
        case 'tagWhite':
          colorId = 2
          break
        case 'tagMultiColor':
          colorId = 3
          break
        default:
          break
      }
      const product = {
        id: uuidv4(),
        item_id: 2,
        color: colorId,
        price: 200.0,
        specifications: {
          front_text: '',
          front_text_color: '',
          front_text_font: '',
        },
      }
      this.addBasketItem(product)
    },
    contactUs() {
      window.location.href = 'https://api.whatsapp.com/send?phone=77751279898&text=%D0%97%D0%B4%D1%80%D0%B0%D0%B2%D1%81%D1%82%D0%B2%D1%83%D0%B9%D1%82%D0%B5!'
    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce-details.scss";
</style>

<style scoped>
th{
  padding-bottom: 1rem;
}
td{
  padding-bottom: 0.2rem !important;
}
.text-wrp {
  display: flex;
}
.text-wrp span {
  align-self: flex-end;
  margin-left: 5px;
}
.margin-bt {
  margin-bottom: 15rem !important;
}
</style>

<template>
  <card-detail />
</template>

<script>
import CardDetail from './apps/e-commerce/e-commerce-product-details/ECommerceCardDetails.vue'

export default {
  components: {
    CardDetail,
  },
}
</script>

<style>

</style>
